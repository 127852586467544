import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
    selector: 'app-positive',
    templateUrl: './positive.component.html',
    styleUrls: ['./positive.component.scss'],
})
export class PositiveComponent implements OnInit {

    constructor(private _router: Router, private fs: AngularFirestore) {
        this.router = _router.url;
    }

    ngOnInit() { }
    router: string;
    model = {};
    fields = [
        {
            key: 'email',
            type: 'input',
            templateOptions: {
                type: 'email',
                label: 'Email ',
                placeholder: 'user@email.com',
                required: true,
            }
        },
        {
            key: 'name',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Name ',
                placeholder: 'John Doe',
            }
        },
        {
            key: 'phone',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'Phone ',
                placeholder: '123-456-7890',
            }
        },
        {
            key: 'experience',
            type: 'input',
            templateOptions: {
                type: 'text',
                label: 'How was your experience?',
                placeholder: 'What went well?'
            }
        }
    ]

    payload = {
        category: "positive",
    }
    form = new FormGroup({});

    submit() {
        const currTime = new Date();
        this.payload['time'] = currTime.toISOString();
        if (this.model['experience']) {
            this.payload['details'] = this.model['experience'];
        }
        this.payload['email'] = this.model['email'];
        if (this.model['name']) {
            this.payload['name'] = this.model['name'];
        }
        if (this.model['phone']) {
            this.payload['phone'] = this.model['phone'];
        }
        this.fs.collection('feedback').add(this.payload);
        this._router.navigateByUrl('/feedback/confirmation', { state: ["Thank you! We look forward to seeing you again."] });
        this.router = '/feedback/confirmation'
    }
}
