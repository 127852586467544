import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { Router } from '@angular/router';

export interface StepType {
  label: string;
  fields: FormlyFieldConfig[];
}

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})

export class HelpComponent implements OnInit {
  router: string;
  constructor(private _router: Router, private fs: AngularFirestore, private cd: ChangeDetectorRef) {
    this.router = _router.url;
  }

  ngOnInit() {
    this.cd.detectChanges();
  }
  activedStep = 0;

  model = {};
  steps: StepType[] = [
    {
      label: 'Cause',
      fields: [
        {
          key: 'reason',
          type: 'radio',
          templateOptions: {
            label: 'How can we help you?',
            required: true,
            options: [
              { value: 'lostfound', label: 'Lost & Found'},
              { value: 'question', label: 'I have a question'},
              { value: 'others', label: 'Something else'}
            ]
          }
        },
      ],
    },
    {
      label: 'Information',
      fields: [
        {
          key: 'email',
          type: 'input',
          templateOptions: {
            type: 'email',
            label: 'Email ',
            placeholder: 'user@email.com',
            required: true,
          }
        },
        {
          key: 'name',
          type: 'input',
          templateOptions: {
            type: 'text',
            label: 'Name ',
            placeholder: 'John Doe',
          }
        },
        {
          key: 'phone',
          type: 'input',
          templateOptions: {
            type: 'text',
            label: 'Phone ',
            placeholder: '123-456-7890',
          }
        },
        {
          key: 'additional',
          type: 'input',
          templateOptions: {
            type: 'text',
            label: 'Additional information',
            placeholder: 'What did you lose?',
            required: true
          }
        }
      ],
    }
  ];

  payload = {
    category: 'help'
  };
  form = new FormArray(this.steps.map(() => new FormGroup({})));
  options = this.steps.map(() => <FormlyFormOptions> {});

  prevStep(step) {
    this.activedStep = step - 1;
  }

  nextStep(step) {
    this.activedStep = step + 1;
  }

  submit() {
    const currTime = new Date();
    this.payload['time'] = currTime.toISOString();
    this.payload['reason'] = this.model['reason'];
    this.payload['email'] = this.model['email'];
    this.payload['details'] = this.model['additional'];
    if(this.model['name']) {
      this.payload['name'] = this.model['name'];
    }
    if(this.model['phone']) {
      this.payload['phone'] = this.model['phone'];
    }
    this.fs.collection('feedback').add(this.payload);
    this._router.navigateByUrl('/feedback/confirmation', { state: ["Thank you. We will reach out to you soon."]});
    this.router = '/feedback/confirmation'
  }
}
